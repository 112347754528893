import * as React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import { useStaticQuery, graphql } from "gatsby"
const VideoPage = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulResourcePage {
      videoSection {
        videoUpload {
          title
          videoLink
        }
      }
    }
  }
`)
  const videoContents = data.contentfulResourcePage.videoSection.videoUpload;
  return (
    <Layout>
      <SEO title="Resources" />
      <div className="sidebar-page-container">
        <div className="services-section">
          <div className="auto-container">
            <div className="sec-title">
              <div className="title">tekki fii</div>
              <h2><span>Resources </span>Videos</h2>
            </div>
            <div className="inner-container">
              <div className="video">
                <div className="tab-content">
                  <div className="row g-3">
                    {videoContents.map(({ videoLink, title }, index) => (
                      <div className="video-block col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                        <div className="card  text-white shadow-sm">
                          <iframe className="card-img-top"
                            height="200pt"
                            src={videoLink}
                            title={title}
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                          <div className="card-body card-content">
                            <h4 className="card-title-header">{title}</h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div >
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default VideoPage

